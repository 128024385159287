import React, { useReducer } from "react"

import { FlexmedCashlessReducer } from "./FlexmedCashlessReducer"
import { useAirtableFMCFormFields } from "./hooks/useAirtableFMCFormFields"

import { generateInitialValues } from "../../../services/context"

let { flattenNode } = require("../../../services/arrays")

const FlexmedCashlessContext = React.createContext()

const FlexmedCashlessProvider = ({ children }) => {
  let formFields = useAirtableFMCFormFields()
  formFields = flattenNode(formFields?.allAirtableallFlexmedCashlessFormFields)
  let [flexmedCashlessState, flexmedCashlessDispatch] = useReducer(
    FlexmedCashlessReducer,
    {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      medicines: [],
      orderTotal: "0.00",
      consentAndAuthorization: [],
      initialState: {
        ...generateInitialValues({ fields: formFields }),
        documents: [],
        medicines: [],
        orderTotal: "0.00",
        consentAndAuthorization: [],
      },
    }
  )

  return (
    <FlexmedCashlessContext.Provider
      value={{ flexmedCashlessState, flexmedCashlessDispatch }}
    >
      {children}
    </FlexmedCashlessContext.Provider>
  )
}

export { FlexmedCashlessContext, FlexmedCashlessProvider }
