import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/services/apollo"

import { AppProvider } from "./src/context/AppContext"
import { MedEnsureProvider } from "./src/components/MedEnsure/MedEnsureContext/MedEnsureContext"
import { RefillsProvider } from "./src/components/MedEnsure/Refills/RefillsContext/RefillsContext"
import { FlexmedCashlessProvider } from "./src/components/Flexmed/FlexmedContext/FlexmedCashlessContext"
import { FlexmedReimburseProvider } from "./src/components/Flexmed/FlexmedContext/FlexmedReimburseContext"
import "./static/styles/global.scss"

export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <MedEnsureProvider>
        <RefillsProvider>
          <FlexmedCashlessProvider>
            <FlexmedReimburseProvider>
              <ApolloProvider client={client}>{element}</ApolloProvider>
            </FlexmedReimburseProvider>
          </FlexmedCashlessProvider>
        </RefillsProvider>
      </MedEnsureProvider>
    </AppProvider>
  )
}
