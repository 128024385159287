export const isObjectEmpty = (object) => {
  for (var i in object) return false
  return true
}

export const combineObjects = (obj1, obj2) => {
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      obj1[key] = obj2[key]
    }
  }
  return obj1
}

export const clearObjectProperties = (obj) => {
  let tempObj = { ...obj }
  if (typeof obj !== "object" || obj === null) {
    return obj
  }

  for (const prop in obj) {
    if (prop !== "company" && prop !== "fieldsToUpdate") {
      if (typeof obj[prop] === "object") {
        obj[prop] = clearObjectProperties(obj[prop])
      } else {
        obj[prop] = ""
      }
    }
  }

  return obj
}

export const toCamelCase = (inputText) => {
  const words = inputText.split(/[\s_]+/)

  // Convert each word to lowercase and capitalize the first letter of each word
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      // Keep the first word lowercase
      return word.toLowerCase()
    } else {
      // Capitalize the first letter of subsequent words
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }
  })

  // Join the words back together without spaces or underscores
  return camelCaseWords.join("")
}

export const isBrowser = () => typeof window !== "undefined"

export const formatString = (object) => {
  if (!object) return ""
  if (typeof object === "object" && object["year"]) return buildDate(object)
  else if (typeof object === "string") return object
  else if (typeof object === "number") return object.toString()
  else if (typeof object === "object") {
    try {
      let data = []
      if (Object.keys(object).length === 0 && object.length === 0) return "N/A"
      object.forEach((item) => {
        if (item.value) data.push(item.value)
        else data.push(item)
      })
      data.sort()
      return data.join(", ")
    } catch (exception) {
      return ""
    }
  } else return "N/A"
}

export const getPathName = () => {
  if (isBrowser()) return window.location.pathname
  return ""
}

export const buildDate = ({ year, month, date }) => {
  if (!year) return "N/A"
  const buildMonth = month?.value ? `${month?.value} ` : ""
  const buildDate = date?.value ? `${("0" + date?.value).slice(-2)} ` : ""
  return `${buildMonth}${buildDate}${year}`
}
