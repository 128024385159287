import React, { useReducer } from "react"

import Modal from "../components/Elements/Modal"
import SlidingModal from "../components/Elements/SlidingModal"
import Toast from "../components/Layout/Toast"

import { initialState } from "./initialState"
import { AppReducer } from "./AppReducer"

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const handleCloseSlidingModal = async (event) => {
    dispatch({ type: "CLOSE_SLIDING_MODAL" })
    await delay(400)
    dispatch({ type: "HIDE_SLIDING_MODAL" })
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isModalActive={state.modal.isActive}
        isFullheight={state.modal.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state.modal.heading}
        modalHeaderClass={state.modal.headerClass}
        modalBackground={state.modal.background}
        hideCloseButton={state.modal.hideCloseButton}
        isCard={state.modal.isCard}
        closeOnClickOutside={state.modal.closeOnClickOutside}
        hideOnMobile={state.modal.hideOnMobile}
      >
        {state.modal.content}
      </Modal>
      <SlidingModal
        isModalActive={state.slidingModal.isActive}
        isFullheight={state.slidingModal.isFullheight}
        isModalClosed={state.slidingModal.isClosed}
        hideModal={() => dispatch({ type: "HIDE_SLIDING_MODAL" })}
        closeModal={handleCloseSlidingModal}
        heading={state.slidingModal.heading}
        modalHeaderClass={state.slidingModal.headerClass}
        modalBackground={state.slidingModal.background}
        hideCloseButton={state.slidingModal.hideCloseButton}
        isCard={state.slidingModal.isCard}
        closeOnClickOutside={state.slidingModal.closeOnClickOutside}
        hasBackground={state.slidingModal.hasBackground}
        hasBackgroundAddons={state.slidingModal.hasBackgroundAddons}
      >
        {state.slidingModal.content}
      </SlidingModal>
      <Toast
        isActive={state.toast.isActive}
        message={state.toast.message}
        color={state.toast.color}
      />
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
