import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../../../../context/services/context"
import { isObjectEmpty } from "../../../../services/general"

export const initialState = {
  documents: [],
  medicines: [],
  agreeToMechanics: [],
  consentAndAuthorization: [],
  selectedMedicine: null,
}

const session = {
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

const forms = {
  SAVE_AGREE_TO_MECHANICS: "SAVE_AGREE_TO_MECHANICS",
  SAVE_RX_DATA: "SAVE_RX_DATA",
  SAVE_HEALTH_SURVEY: "SAVE_HEALTH_SURVEY",
  SAVE_EMPLOYEE_INFORMATION: "SAVE_EMPLOYEE_INFORMATION",
  SAVE_AGREE_CONSENT_MEDENSURE: "SAVE_AGREE_CONSENT_MEDENSURE",
  RESET_DETAILS: "RESET_DETAILS",
}

const upload = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
}

const order = {
  SAVE_MEDICINES: "SAVE_MEDICINES",
  SAVE_SELECTED_MEDICINE: "SAVE_SELECTED_MEDICINE",
}

export const RefillsReducer = (state, action) => {
  switch (action.type) {
    case forms.SAVE_AGREE_TO_MECHANICS:
      return { ...state, ...action.payload }

    case forms.SAVE_RX_DATA:
      return { ...state, ...action.payload }

    case forms.SAVE_HEALTH_SURVEY:
      return { ...state, ...action.payload }

    case forms.SAVE_EMPLOYEE_INFORMATION:
      return { ...state, ...action.payload }

    case forms.SAVE_AGREE_CONSENT_MEDENSURE:
      return { ...state, consentAndAuthorization: action.payload }

    case upload.SAVE_DOCUMENTS:
      return { ...state, documents: [...action.payload] }

    case order.SAVE_MEDICINES:
      return { ...state, medicines: [...action.payload] }

    case order.SAVE_SELECTED_MEDICINE:
      return { ...state, selectedMedicine: action.payload }

    case session.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved

    case session.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState))
        return {
          ...state.initialState,
          initialState: { ...state.initialState },
        }

      return {
        ...returnState,
      }

    case session.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...state.initialState,
        initialState: { ...state.initialState },
      }

    case forms.RESET_DETAILS:
    default:
      return {
        ...state.initialState,
        initialState: { ...state.initialState },
      }
  }
}
