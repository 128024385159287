import { initialState } from "./initialState"
import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "./services/context"
import { isObjectEmpty } from "../services/general"

const RESET_DETAILS = "RESET_DETAILS"

const modal = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
}

const slidingModal = {
  SHOW_SLIDING_MODAL: "SHOW_SLIDING_MODAL",
  HIDE_SLIDING_MODAL: "HIDE_SLIDING_MODAL",
  CLOSE_SLIDING_MODAL: "CLOSE_SLIDING_MODAL",
}

const documents = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
}

const medicines = {
  SAVE_MEDICINES: "SAVE_MEDICINES",
}

const toast = {
  SHOW_TOAST: "SHOW_TOAST",
  HIDE_TOAST: "HIDE_TOAST",
}

const session = {
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

const auth = {
  SAVE_AUTH: "SAVE_AUTH",
}

const enrollment = {
  SAVE_ENROLLMENT: "SAVE_ENROLLMENT",
  SAVE_AGREE_ENROLLMENT: "SAVE_AGREE_ENROLLMENT",
}

const consent = {
  SAVE_AGREE_CONSENT_MEDENSURE: "SAVE_AGREE_CONSENT_MEDENSURE",
  SAVE_AGREE_CONSENT_FLEXMED_CASHLESS: " SAVE_AGREE_CONSENT_FLEXMED_CASHLESS",
}

const banner = {
  SAVE_BANNER: "SAVE_BANNER",
}

export let AppReducer = (state, action) => {
  switch (action.type) {
    case documents.SAVE_DOCUMENTS:
      return {
        ...state,
        documents: [...action.payload],
      }

    case medicines.SAVE_MEDICINES:
      return {
        ...state,
        medicines: [...action.payload],
      }

    case consent.SAVE_AGREE_CONSENT_MEDENSURE:
      return {
        ...state,
        agreeToConsent: {
          ...state.agreeToConsent,
          medEnsure: action.payload,
        },
      }

    case consent.SAVE_AGREE_CONSENT_FLEXMED_CASHLESS:
      return {
        ...state,
        agreeToConsent: {
          ...state.agreeToConsent,
          flexmedCashless: action.payload,
        },
      }

    case banner.SAVE_BANNER:
      return {
        ...state,
        banner: action.payload,
      }

    case modal.SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case modal.HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal, isActive: false } }

    case slidingModal.SHOW_SLIDING_MODAL:
      return { ...state, slidingModal: { ...action.payload, isActive: true } }

    case slidingModal.HIDE_SLIDING_MODAL:
      return {
        ...state,
        slidingModal: { ...initialState.slidingModal, isActive: false },
      }

    case slidingModal.CLOSE_SLIDING_MODAL:
      return {
        ...state,
        slidingModal: { ...state.slidingModal, isClosed: true },
      }

    case toast.SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case toast.HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case session.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved

    case session.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }

      return {
        ...returnState,
        modal: {
          ...initialState.modal,
        },
        slidingModal: {
          ...initialState.slidingModal,
        },
      }

    case session.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }

    case auth.SAVE_AUTH:
      return {
        ...state,
        auth: { ...state.auth, ...action.payload },
      }

    case enrollment.SAVE_AGREE_ENROLLMENT:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          agreeToConsent: action.payload,
        },
      }

    case enrollment.SAVE_ENROLLMENT:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          ...action.payload,
        },
      }

    case RESET_DETAILS:
      return { ...initialState }

    default:
      return { ...initialState }
  }
}
