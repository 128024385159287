import React, { useReducer } from "react"

import { FlexmedReimburseReducer } from "./FlexmedReimburseReducer"
import { useAirtableFMRFormFields } from "./hooks/useAirtableFMRFormFields"

import { generateInitialValues } from "../../../services/context"

let { flattenNode } = require("../../../services/arrays")

const FlexmedReimburseContext = React.createContext()

const FlexmedReimburseProvider = ({ children }) => {
  let formFields = useAirtableFMRFormFields()
  formFields = flattenNode(formFields?.allAirtableallFlexmedReimburseFormFields)
  let [flexmedReimburseState, flexmedReimburseDispatch] = useReducer(
    FlexmedReimburseReducer,
    {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      receipts: [],
      consentAndAuthorization: [],
      initialState: {
        ...generateInitialValues({ fields: formFields }),
        documents: [],
        receipts: [],
        consentAndAuthorization: [],
      },
    }
  )

  return (
    <FlexmedReimburseContext.Provider
      value={{ flexmedReimburseState, flexmedReimburseDispatch }}
    >
      {children}
    </FlexmedReimburseContext.Provider>
  )
}

export { FlexmedReimburseContext, FlexmedReimburseProvider }
