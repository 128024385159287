export const initialState = {
  medicines: [],
  documents: [],
  banner: "",
  auth: {
    email: "",
    password: "",
    confirmPassword: "",
    hasBeenVerified: false,
  },
  enrollment: {
    agreeToConsent: [],
    firstName: "",
    lastName: "",
    birthday: {
      month: {
        label: "",
        value: "",
      },
      date: {
        label: "",
        value: "",
      },
      year: "",
    },
    gender: "",
    mobileNumber: "",
    viberNumber: "",
    employeeNumber: "",
    site: {
      label: "",
      value: "",
    },
    department: "",
    address: {
      addressType: "Home",
      streetAddress: "",
      province: {
        label: "",
        value: "",
      },
      city: {
        label: "",
        value: "",
      },
    },
    emergencyContactName: "",
    emergencyContactMobileNumber: "",
    emergencyContactRelationship: "",
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
    closeOnClickOutside: false,
    hideOnMobile: false,
  },
  slidingModal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    isClosed: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    hasBackground: false,
    hasBackgroundAddons: false,
    background: {
      color: null,
      opacity: "100",
    },
    closeOnClickOutside: false,
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  agreeToConsent: {
    medEnsure: [],
    flexmedCashless: [],
  },
}
